<template lang="pug">
  .bg-gray-300.p-1.w-full.max-w-prose.mx-auto.shadow-content-box(
    style='height: 30rem;'
  )
    Splitpanes(:horizontal='true')
      Pane
        .flex.flex-col.w-full
          .flex
            TabBar(
              v-model='openTabs'
              :dataId='randomId()'
              :showAddButton='false'
              :activeTabId='activeTabId'
              :isActive='true'
              :scrollTabIntoView='false'
              @dragStart='onDragStart'
              @dragEnd='onDragEnd'
              @switchTab='switchTab'
              @tabClose='onCloseTab'
            )

          .flex-grow.min-h-0(:key='activeTabId')
            Editor(
              v-if='currentSymbol'
              ref='panelEditor'
              :currentSymbol='currentSymbol'
              :editorData='editorData'
              :allSymbols='localSymbols'
              :savedRules='savedRules'
              :startSymbolId='startSymbolId'
              :handleOpenSymbol='openSymbol'
              @update='updateOutput'
              @createSymbol='createSymbol'
            )

      Pane.flex.flex-col.w-full
        .flex
          TabBar(
            v-model='resultTab'
            :dataId='randomId()'
            :showAddButton='false'
            :scrollTabIntoView='false'
            activeTabId='result-tab'
            :isActive='false'
          )
        .flex-grow.min-h-0
          TutorialOutput(
            :errors='output.errors'
            :text='output.text'
            @generate='generate'
          )
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import keyBy from 'lodash/keyBy'
import Editor from '@/components/editor/Editor'
import TutorialOutput from '@/components/output/TutorialOutput.vue'
import TabBar from '@/components/tabbar/TabBar.vue'
import { emptyDoc } from '@/store/modules/entities/models'
import { createId } from '@/util'

export default {
  name: 'TutorialPanel',

  components: {
    TabBar, Editor, TutorialOutput
  },

  props: {

    initialSymbols: {
      type: Array,
      required: true
    },

    generateOnStart: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      localSymbols: [],
      activeTabId: '',
      openTabs: [],
      resultTab: [{ id: 'result-tab', label: 'Result', canClose: false }],
      isDragging: false,
      savedRules: {},
      output: {
        text: '',
        errors: []
      }
    }
  },

  created () {
    this.activeTabId = this.initialSymbols[0].id
    this.localSymbols = this.initialSymbols
    this.openTabs = this.makeTabs(this.initialSymbols)
  },

  mounted () {
    if (this.generateOnStart) {
      this.updateOutput(this.$refs.panelEditor.editor.getJSON())
    }
  },

  computed: {

    localSymbolsById () {
      return keyBy(this.localSymbols, 'id')
    },

    currentSymbol () {
      return this.localSymbolsById[this.activeTabId]
    },

    editorData () {
      return {
        id: 'test-editor',
        symbolId: this.currentSymbol.id,
        wordWrap: false,
        selection: { isNodeSel: false, from: 0, to: 1 },
        scrollPos: { top: 0, left: 0 }
      }
    },

    startSymbolId () {
      return this.initialSymbols[0].id
    }

  },

  methods: {

    randomId () {
      return createId('tutorial-panel')
    },

    makeTabs (symbols) {
      return symbols.map(s => ({
        id: s.id,
        label: s.name,
        canClose: s.id !== this.startSymbolId
      }))
    },

    onDragStart ({ $event, tab }) {
      this.isDragging = true
    },

    onDragEnd ({ $event, tab }) {
      this.isDragging = false

      if (tab.id !== this.activeTabId) {
        this.switchTab(tab)
      }
    },

    switchTab (tab) {
      this.activeTabId = tab.id
    },

    onCloseTab (tab) {
      const tabIndex = this.openTabs.findIndex(t => t.id === tab.id)
      this.openTabs = this.openTabs.filter(t => t.id !== tab.id)
      this.activeTabId = this.openTabs[tabIndex] ? this.openTabs[tabIndex].id : this.openTabs[tabIndex - 1].id
    },

    createSymbol ({ id, name, color }) {
      const doc = {
        id,
        name,
        color,
        content: emptyDoc
      }

      this.localSymbols.push(doc)
    },

    openSymbol ({ attrs: { id } }) {
      // select tab if already open
      const openTab = this.openTabs.find(t => t.id === id)
      if (openTab) {
        this.activeTabId = openTab.id
        return
      }

      // insert tab and set current symbol
      const newTab = this.makeTabs([this.localSymbolsById[id]])[0]
      this.openTabs.push(newTab)
      this.activeTabId = this.openTabs[this.openTabs.length - 1].id
    },

    updateOutput (editorJSON) {
      const symbol = this.localSymbolsById[this.activeTabId]

      // overwrite currentSymbol with new content
      const symbolClone = cloneDeep(symbol)
      symbolClone.content = editorJSON.content

      const index = this.localSymbols.findIndex(s => s.id === symbol.id)
      this.$set(this.localSymbols, index, symbolClone)

      this.generate()
    },

    generate () {
      const { text, messages, savedRules } = this.$generate({
        symbolId: this.initialSymbols[0].id,
        symbols: this.localSymbolsById
      })

      this.savedRules = savedRules
      this.output.text = text
      this.output.errors = messages.errors
    }

  }
}
</script>
