<template lang="pug">
  Output(
    :errors='errors'
    :text='text'
    :menuItems='menuItems'
  )
</template>

<script>
import Output from '@/components/output/Output'

export default {
  name: 'TutorialOutput',

  components: { Output },

  props: {

    errors: {
      type: Array,
      default: () => []
    },

    text: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      menuItems: [
        {
          label: 'Generate',
          showLabel: false,
          icon: 'ReloadIcon',
          tippy: {
            placement: 'top',
            content: 'Generate'
          },
          onClick: () => {
            this.$emit('generate')
          }
        }
      ]
    }
  }
}
</script>
