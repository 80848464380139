<template lang="pug" functional>
  .bg-blue-50.bg-opacity-40.px-4.py-6.infobox-shadow
    .max-w-prose.mx-auto.text-blue-900.relative
      .absolute.-left-10.top-0
        InformationVariantIcon(:size='30').text-blue-300
      slot
</template>

<script>
export default {
  name: 'TutorialInfobox'
}
</script>

<style lang="scss">
.infobox-shadow {
  box-shadow: 5px 5px 0px rgba(189, 191, 255, .50);
}
</style>
