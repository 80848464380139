<template lang="pug" functional>
  .prose-content.max-w-prose.mx-auto
    slot
</template>

<script>
export default {
  name: 'TutorialInfobox'
}
</script>
