<template lang="pug" functional>
  .bg-gray-50.py-8.pb-6.mt-6.mb-2.space-y-6
    slot
</template>

<script>
export default {
  name: 'TutorialContentbox'
}
</script>
