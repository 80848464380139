<template lang="pug">
  main.pt-14
    ModalContainer

    section.pb-10.max-w-3xl.mx-auto
      .prose-content.max-w-prose.mx-auto
        h1 Tutorial
        p Welcome to the interactive tutorial for <a :href="plauderUrl" rel="noopener">Plauder</a>.
        p Plauder is a user interface for generating text. Most of the time you create placeholders and fill them with text or other placeholders. As you write, Plauder randomly fills all placeholders and creates variations of your input. Plauder works <a href="https://en.wikipedia.org/wiki/Recursion" target="_blank" rel="noopener">recursively</a>, which means you can put placeholders inside placeholders.

    section.pb-14.max-w-3xl.mx-auto
      .prose-content.max-w-prose.mx-auto.border-2.border-black.p-5
        .uppercase.text-base.font-bold.mb-3 Contents
        ul
          li.py-1
            a(href='#symbolsandrules') Symbols and rules
          li.py-1
            a(href='#symbolswithinsymbols') Symbols within symbols
          li.py-1
            a(href='#symboloptions') Symbol options
          li.py-1
            a(href='#savingrules') Saving rules
          li.py-1
            a(href='#creatingvariants') Creating variants
          li.py-1
            a(href='#keyboardshortcuts') Keyboard shortcuts

    section.pb-14.max-w-3xl.mx-auto
      .prose-content.max-w-prose.mx-auto
        h2(id='symbolsandrules') Symbols and rules
        p The first thing to know is that the placeholders in Plauder are called "symbols". We open and edit symbols in tabs.
        p Take a look at the "animal"-tab below. It contains a list of animal names. The second thing to know is that each line in that list is a "rule". A rule can be one word, several sentences or any text in any language you like.

      TutorialContentbox
        TutorialInstructionbox Type something in the editor and see how the result in the "Result"-tab below changes.
        TutorialPanel(
          :initial-symbols="filterSymbols(['s-A0CZsJxt5YHMvG'])"
        )
      TutorialInfobox As you write, Plauder combines all rules from the "animal"-symbol into a random new order and displays the result in the "Result" tab. Not very interesting, yet, so let's get to the next example.

    section.pb-14.max-w-3xl.mx-auto
      .prose-content.max-w-prose.mx-auto
        h2(id='symbolswithinsymbols') Symbols within symbols
        p The lines in Plauder are called "rules" because they determine how the text is put together. Take a look at the "Start"-tab below and how the first rule is composed of regular text and three symbols: <i>color</i>, <i>animal</i> and <i>something</i>.
        p Plauder takes a starting symbol ("Start"-tab) and replaces all the other symbols inside with one of several rules.

      TutorialContentbox
        TutorialInstructionbox
          h3 Things you can do here:
          ul.list-disc.pl-5.space-y-2
            li Create or insert a new symbol by pressing <kbd>#</kbd> on your keyboard in the editor. This opens the symbol menu and shows all available symbols. Use the arrow keys <kbd>↑</kbd> or <kbd>↓</kbd> to navigate through the menu and <kbd>↵ Enter</kbd> to select something. To create a new symbol, type a symbol name and then select "Create new" from the menu.
            li Double-click a colored symbol to open it in a tab or click on a tab to see the contents of a symbol.
            li Open the "something"-Symbol and add another rule by pressing <kbd>↵ Enter</kbd> at the end of a line and type something.

        TutorialPanel(
          :initial-symbols="filterSymbols(['symbol-origin', 's-rGKCofSmUtfbqJ', 's-A0CZsJxt5YHMvG', 's-3GENvTW-Ls35MG'])"
          :generate-on-start='true'
        )

      TutorialInfobox If you create a lot of tabs, Plauder can quickly become cluttered. In the real editor on <a :href="plauderUrl" class="text-blue-600" rel="noopener">{{ plauderUrl }}</a>, the interface is larger and adaptable and you have features like <em>search</em>, <a href="#keyboardshortcuts" class="text-blue-600"><em>keyboard shortcuts</em></a> and other tools that make the user experience more comfortable. These features are not available in the tutorial.

    section.pb-14.max-w-3xl.mx-auto
      .prose-content.max-w-prose.mx-auto

        h2(id='symboloptions') Symbol options
        p After inserting a symbol with the <kbd>#</kbd>-key, you can tell Plauder how to process its content. For example, you can adjust capitalization, change verbs to past tense, change nouns to their plural form, and more.
        p Plauder offers these and other functions for the English language. You can find them by clicking on a symbol, which opens a black menu above or below the symbol.
        p The examples below illustrate the use of symbol options:

      TutorialContentbox
        TutorialInstructionbox
          h3 Changing capitalization
          ol.list-decimal.pl-5.space-y-2
            li Look at the content of the "Result"-tab. The first word needs to be capitalized, because it's the beginning of a sentence.
            li To capitalize the first word, click on the <strong>first</strong> "sometimes"-symbol to open the options menu and then click on <strong>Options</strong> --> <strong>Case</strong> --> <strong>Capitalize</strong>.
            li Take a look at the "Results"-tab and watch how the <strong>first symbol</strong> is being capitalized when you activate this option (and a red dot appears).

        TutorialPanel(
            :initial-symbols="filterSymbols(['s-mgPeXJGHf-kgnm', 's-I-2oOZzbEHN_37'])"
            :generate-on-start='true'
          )

      TutorialContentbox
        TutorialInstructionbox
          h3 Inserting articles
          ol.list-decimal.pl-5.space-y-2
            li Look at the content of the "Result"-tab. There is an article missing in front of each adjective!
            li To insert one click on the orange "adjective" symbol to open the options menu and then on <strong>Options</strong> --> <strong>Grammar</strong> --> <strong>Article</strong>.
            li Click the "Article"-button several times to enable/disable this option and watch how an indefinite article (a/an) is being inserted/removed in front of each adjective.

        TutorialPanel(
          :initial-symbols="filterSymbols(['s-9wy1wNAumRQUQW', 's-rZJbMoZePf6eaq', 's-nwR4GzQ01Rq2iC', 's-A0CZsJxt5YHMvG'])"
          :generate-on-start='true'
        )

      TutorialContentbox
        TutorialInstructionbox
          h3 And more…
          p There are more symbol options. Just try them out yourself! Two slightly more complex ones will be explained in the next chapters.

    section.pb-14.max-w-3xl.mx-auto
      .prose-content.max-w-prose.mx-auto
        h2(id='savingrules') Saving rules
        p Sometimes you may want to use the same name of a person or place throughout your text. By default, Plauder selects a new random rule for each symbol. But there is a function that allows you to save the selection of rules.

      TutorialContentbox
        TutorialInstructionbox
          h3 How saving rule selection works:
          ul.list-disc.pl-5.space-y-2
            li In order for Plauder to remember the selection of a rule, you must first associate a symbol in the text with a name.
            li Click the first purple symbol below and then on <strong>Options</strong> --> <strong>Save</strong>. A white text input appears, that is prefilled with "Sibling1". That means the first "person"-symbol in the text is assigned with the name "Sibling1".
            li The first time Plauder selects a rule for the "person"-symbol, it associates that selection with the name "Sibling1". If the name "Sibling1" is assigned to another "person"-symbol somewhere else, Plauder will output the same rule.
            li In the following example, only one symbol is used ("person"), but the rule selection is cached twice (with the names "Sibling1" and "Sibling2").

        TutorialPanel(
          :initial-symbols="filterSymbols(['s-Bf9crlRCUSsyTK', 's-MSeDeiVzZ6vUQG'])"
          :generate-on-start='true'
        )
      TutorialInfobox
        div Once you've assigned a name to a symbol, the cached version becomes selectable in the symbol menu (<kbd>#</kbd>-key).
        .mt-4
          .mb-2 Example (GIF):
          img.border-2.border-blue-50(src='img/tutorial/saving_rule_selection.gif' loading="lazy")

    section.pb-14.max-w-3xl.mx-auto
      .prose-content.max-w-prose.mx-auto
        h2(id='creatingvariants') Creating variants
        //- p.font-bold 🚧&nbsp;Work in progress (example needed)&nbsp;🚧
        p Plauder allows you to define different alternative versions of a rule. Later in the in the symbol menu you decide which one should be selected during text generation.

      TutorialContentbox
        TutorialInstructionbox
          h3 How to create variants:
          ul.list-disc.pl-5.space-y-2
            li You create variants of a rule by pressing <kbd>Alt</kbd>+<kbd>↵ Enter</kbd> in the editor. This turns a rule into a group. This group can have multiple rule variants.
            li Pressing <kbd>↵ Enter</kbd> creates another variant in the next line. The number in front of the variant is to be defined later in the symbol menu (<strong>Options</strong> --> <strong>Variant</strong>).
            li If you press Enter in an empty variant, you exit the mode in which variants are created.

      TutorialContentbox
        TutorialInstructionbox
          h3 An example:
          p The following example is a bit demanding:
          ul.list-disc.pl-5.space-y-2
            li First, the result from the "verb"-symbol is cached with the name "saved", so it's always the same (see <a href="#savingrules">Saving rules</a>).
            li Then, the second "verb"-symbol always returns only the second variant (see its symbol-options <strong>Options</strong> --> <strong>Variant</strong>).
            li Double click on the green "verb"-symbol or select the "verb"-tab and see how its content is structured. Click the refresh button at the bottom right of the result tab a few times and watch how the result changes.

        TutorialPanel(
          :initial-symbols="filterSymbols(['s-LcZqZ8tKTX0JGr', 's-OcdHZv91x3pklF'])"
          :generate-on-start='true'
        )

      TutorialInfobox Variants were created mainly because the modification of grammatical properties of a word (inflection etc.) is not possible by machine in some languages.

    section.pb-24.max-w-3xl.mx-auto
      .prose-content.max-w-prose.mx-auto
        h2(id='keyboardshortcuts') Keyboard shortcuts
        p Here is an overview of all keyboard shortcuts available in Plauder::
        table
          thead
            tr
              th Keybinding
              th Command
          tbody
            tr
              td
                kbd #
              td Open symbol menu (when caret is inside the editor)
            tr
              td
                kbd Esc
              td Close active ab
            tr
              td
                kbd ↵ Enter
              td Open symbol in tab (when symbol is selected)
            tr
              td <kbd>Alt</kbd>+<kbd>↵ Enter</kbd>
              td Enter/exit mode to create variants
            tr
              td <kbd>⌘P</kbd> or <kbd>Ctrl</kbd>+<kbd>P</kbd>
              td Open symbol picker

</template>

<script>
import TutorialPanel from '@/components/tutorial/TutorialPanel'
import TutorialInfobox from '@/components/tutorial/TutorialInfobox'
import TutorialInstructionbox from '@/components/tutorial/TutorialInstructionbox'
import TutorialContentbox from '@/components/tutorial/TutorialContentbox'
import allSymbols from '@/components/tutorial/tutorial-story.json'

export default {
  name: 'Tutorial',

  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'Tutorial'
  },

  components: {
    TutorialPanel, TutorialInfobox, TutorialInstructionbox, TutorialContentbox
  },

  data () {
    return {
      allSymbols,
      plauderUrl: process.env.VUE_APP_PUBLIC_URL
    }
  },

  methods: {
    filterSymbols (symbolIds) {
      const allSymbols = this.allSymbols
      return symbolIds.map(id => allSymbols.find(s => s.id === id))
    }
  }
}
</script>
